/* eslint-disable */
import React from 'react'
import styled from 'styled-components'
import Button from '../components/atoms/button.atom'
import { Container } from '../components/atoms/container.atom'

import Layout from '../components/organisms/layout.organism'
import SEO from '../components/organisms/seo.organism'
import image from '../images/404.svg'
import theme from '../theme'

const StyledLayout = styled(Layout)`
  text-align: center;
  ${theme.media.tablet`
    padding-top: 60px;
  `}
`

const Text = styled.p`
  && {
    margin-bottom: 20px;
  }
`

const Text404 = styled.h1`
  color: ${theme.colors.purple};
  font-size: 9rem;
  margin-bottom: 0;
`
const Image = styled.img`
  max-width: 100%;
  display: block;
  margin: 0 auto;
  margin-top: 80px;
`

const NotFoundPage = () => (
  <StyledLayout>
    <Container>
      <Text404>404</Text404>
      <h2>Seite nicht gefunden</h2>
      <Text>Es tut uns leid, die von Ihnen angeforderte Seite existiert nicht.<br />Bitte gehen Sie zurück zur Startseite.</Text>
      <Button to="/">Nach Hause</Button>
      <Image width="300" height="300" alt="Seite nicht gefunden" title="Seite nicht gefunden" src={image} />
      
    </Container>
  </StyledLayout>
)

export default NotFoundPage
